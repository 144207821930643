import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import isNumber from 'lodash/isNumber';
import encodeUnsafeCharacters from './encodeUnsafeCharacters';



const applyFilter = ({
  values = {},
  searchQueryParams,
  searchFilterFields = [],
  setSearchParams = () => {},
  setSearchField = () => {},
  toggleFilterSidebar = () => {},
  navigationSource = '',
  callbacks = [],
}) => {
  const queryParams = {};

  Array.from(searchQueryParams.entries())
    .forEach(([ key, value ]) => {
      queryParams[key] = key === 'page' ? 1 : value;
    });

  queryParams.search = Object.entries(values)
    .map(([ field, value ]) => {
      if (searchFilterFields.includes(field)) {
        if ((isArray(value) && !value.length) || value === null || (isNumber(value) ? false : !value)) {
          return '';
        }
        if (isString(value)) {
          return [ 'id', 'client_id', 'image_id', 'folder', 'source_id', 'code' ].includes(field)
            ? `${field}:${value.split(/[ ,\t]+/)}`
            : `${field}:${encodeUnsafeCharacters(value.trim())}`;
        }
        return `${field}:${value}`;
      }
      return '';
    }).filter((item) => item !== '').join(';');

  if (navigationSource && !queryParams.search.includes(`source:${navigationSource}`)) {
    queryParams.search += `;source:${navigationSource}`;
  }

  setSearchField('');
  setSearchParams(queryParams, { replace: true });
  toggleFilterSidebar();

  callbacks.forEach((callback) => callback());
};

export default applyFilter;
